<template>
  <div class="flex-row align-items-center justify-content-start" :class="{ 'mt-3': isMobile }">
    <label for="managed-by-devpost-checkbox" class="px-2 py-2 flex-row align-items-center" :class="{ 'ml-1': isMobile }">
      <input type="checkbox"
             id="managed-by-devpost-checkbox"
             :class="{ 'is-mobile': isMobile }"
             :name="filter.paramName"
              v-model="managedByDevpostToggleState"
             >
      <img :src="devpostBadgeUrl" v-if="!isMobile"/>
      <span class="item-label">
         Managed by Devpost
      </span>
    </label>
    <a href="https://help.devpost.com/hc/en-us/articles/12898563216916" target="_blank" rel="noopener noreferrer">
      <i class="fas fa-info-circle"></i>
    </a>
  </div>
</template>

<script setup>
  import { computed, defineProps } from 'vue';
  import { useStore } from 'vuex';

  import devpostBadgeUrl from '@/images/devpost-icon-rgb30px.png';

  const props = defineProps({
    filter: { type: Object, required: true },
    isMobile: { type: Boolean, required: false, default: false }
  })

  const store = useStore();

  const managedByDevpostToggleState = computed({
    get: () => store.getters['filters/getManagedByDevpostBadgeState'],
    set: (value) => {
      store.dispatch("filters/setManagedByDevpostBadge", value);
      store.dispatch("hackathons/search");
    }
  })
</script>

<style scoped lang="scss">
  @import "hackathon_search/filters";

  #managed-by-devpost-checkbox {
    margin-bottom: 0px;

    &.is-mobile {
      margin-right: 21px;
    }
  }

  img {
    width: 16px;
    margin-right: 4px;
  }
</style>
