<template>
  <div v-if="hackathons.length > 0">
    <hr class="mb-5">

    <div class="row">
      <div class="large-12 columns">

        <div class="section-title bold">
          <h3 class="subheader section-title-left">
            Similar hackathons
          </h3>
        </div>

        <hackathon-grid :hackathons="hackathons" v-slot="props">
          <hackathon-result
            isMedium
            :hackathon="props.hackathon"
            :refParams="{ ref_feature: 'challenge', ref_medium: 'similar-hackathons' }">
          </hackathon-result>
        </hackathon-grid>

      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import { useStore } from 'vuex'

  import HackathonResult from '@/components/HackathonResult'
  import HackathonGrid from '@/components/HackathonGrid'
  import useIsMobile from '@/scripts/use_is_mobile'

  const store = useStore();
  store.dispatch("similarHackathons/fetchSimilarHackathons");

  const { desktopView } = useIsMobile();

  const hackathons = computed(() => store.getters["similarHackathons/hackathons"]);
</script>

<style lang="scss" scoped>
  .is-desktop {
    .large-6 {
      .hackathon-tile {
        margin-right: 1rem;
      }
    }

    .hackathon-tile.featured {
      margin-left: 28px !important;
    }
  }
</style>
