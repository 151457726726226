import { mountApp } from '@/apps/mount_app';
import HackathonRecommendations from '@/components/HackathonRecommendations.vue'
import store from '@/stores/challenges_app_store'

export default () => {
  mountApp({
    domId: "hackathon-recommendations",
    component: HackathonRecommendations,
    beforeMount: (app) => app.use(store)
  })
}
