import { createStore, createLogger } from 'vuex'

import similarHackathons from './modules/similar_hackathons_store'

const debug = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test'

let plugins = []
if (debug) plugins.push(createLogger())

const store = createStore({
  modules: {
    similarHackathons
  },
  strict: debug,
  plugins: plugins
})

export default store
