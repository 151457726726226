<template>
  <div class="hackathon-tile clearfix"
    :class="[
      (hasSidebar ? '' : 'without-sidebar'),
      (isSmall ? 'is-small' : ''),
      hackathon.open_state,
      (hover ? 'hover' : ''),
      (featured ? 'featured' : ''),
      (mobileView ? 'is-mobile' : '')
    ]">

    <!-- featured tab -->
    <a :href="hackathonUrl"
      v-if="featured"
      @mouseenter="onMouseOver"
      @mouseleave="onMouseLeave"
      class="featured-tab align-items-center">
      <div>Featured</div>
    </a>

    <a :href="hackathonUrl" class="flex-row tile-anchor"
      @mouseenter="onMouseOver"
      @mouseleave="onMouseLeave"
      @focus="onMouseOver"
      @blur="onMouseLeave">
      <div class="main-content">

        <!-- Mobile -->
        <template v-if="mobileView">
          <div class="flex-row justify-content-start align-items-center mb-4">
            <img :src="hackathon.thumbnail_url" class="hackathon-thumbnail mr-6">
            <div>
              <hackathon-location :hackathon="hackathon"></hackathon-location>
            </div>
          </div>

          <h3 class="mb-4">{{hackathon.title}}</h3>

          <div class="flex-row justify-content-start align-items-center" :class="{ 'mb-6': !isSmall }">
            <div class="hackathon-status mr-4">
              <hackathon-status :hackathon="hackathon"></hackathon-status>
            </div>
            <div>
              <strong><hackathon-submission-period :hackathon="hackathon"></hackathon-submission-period></strong>
            </div>
          </div>

          <hackathon-stats v-if="!isSmall" :hackathon="hackathon"></hackathon-stats>
        </template>

        <!-- Desktop -->
        <template v-else>
          <img :src="hackathon.thumbnail_url" class="hackathon-thumbnail">

          <div class="content">
            <h3 class="mb-4">{{hackathon.title}}</h3>

            <div class="flex-row justify-content-start" :class="{ 'mb-6': !isSmall }">
              <div class="hackathon-status mr-4">
                <hackathon-status :hackathon="hackathon"></hackathon-status>
              </div>
              <div>
                <hackathon-location :hackathon="hackathon"></hackathon-location>
              </div>
            </div>

            <hackathon-stats v-if="!isSmall" :hackathon="hackathon"></hackathon-stats>
          </div>
        </template>
      </div>

      <div class="side-info" v-if="hasSidebar">
        <!-- host -->
        <div class="mb-4 host" v-if="hackathon.organization_name">
          <info-with-icon icon="flag">
            <hackathon-host :name="hackathon.organization_name"
              @onMouseLeave="onMouseOver"
              @onMouseOver="onMouseLeave">
            </hackathon-host>
          </info-with-icon>
        </div>

        <!-- location -->
        <div class="mb-4" v-if="desktopView">
          <info-with-icon icon="calendar">
            <hackathon-submission-period :hackathon="hackathon"></hackathon-submission-period>
          </info-with-icon>
        </div>

        <div class="managed-by-devpost mb-4 align-items-center" v-if="hackathon.managed_by_devpost_badge">
          <img :src="devpostBadgeUrl"/>
          <a @click.prevent="toggleManagedByDevpost" class="mr-1"><span>Managed by Devpost</span></a>
          <a href="https://help.devpost.com/hc/en-us/articles/12898563216916" target="_blank">
            <i class="fas fa-info-circle"></i>
          </a>
        </div>

        <!-- Invite only -->
        <div class="mb-4" v-if="hackathon.invite_only">
          <div v-if="desktopView">
            <ToolTipped :text="hackathon.eligibility_requirement_invite_only_description">
              <info-with-icon icon="envelope-open-text">
                <span class="invite-only">Invite only</span>
              </info-with-icon>
            </ToolTipped>
          </div>
          <div v-else>
            <info-with-icon icon="envelope-open-text">
              <span>Invite only: {{ hackathon.eligibility_requirement_invite_only_description }}</span>
            </info-with-icon>
          </div>
        </div>

        <!-- themes -->
        <hackathon-themes :themes="hackathon.themes"
          @onMouseLeave="onMouseOver"
          @onMouseOver="onMouseLeave">
        </hackathon-themes>
      </div>
    </a>

    <!-- hover tab -->
    <div class="tab">
      <i class="fas fa-angle-right"></i>
    </div>

    <!-- status tab -->
    <div class="status-tab"></div>
  </div>
</template>

<script>
  // TODO: A lot of the css should be moved to regular css files, doesnt need to
  // be scoped.

  import { computed, ref } from 'vue';

  import HackathonThemes from '@/components/hackathon_results/HackathonThemes.vue';
  import HackathonHost from '@/components/hackathon_results/HackathonHost.vue';
  import HackathonStatus from '@/components/hackathon_results/HackathonStatus.vue';
  import HackathonSubmissionPeriod from '@/components/hackathon_results/HackathonSubmissionPeriod.vue';
  import HackathonStats from '@/components/hackathon_results/HackathonStats.vue';
  import HackathonLocation from '@/components/hackathon_results/HackathonLocation.vue';

  import InfoWithIcon from '@/components/InfoWithIcon.vue';
  import ToolTipped from '@/components/ToolTipped.vue';

  import useIsMobile from '@/scripts/use_is_mobile';

  import devpostBadgeUrl from '@/images/devpost-icon-rgb30px.png';

  import { useStore } from 'vuex';

  export default {
    name: "HackathonResult",

    props: {
      hackathon: {
        type: Object,
        required: true
      },
      isMedium: { // no sidebar
        type: Boolean,
        required: false,
        default: false
      },
      isSmall: { // no sidebar and no participant count or prize amount
        type: Boolean,
        required: false,
        default: false
      },
      isFeatured: {
        type: Boolean,
        required: false,
        default: null
      },
      refParams: {
        type: Object,
        required: false,
        default: {}
      }
    },
    components: {
      HackathonThemes,
      HackathonHost,
      HackathonStatus,
      HackathonSubmissionPeriod,
      HackathonStats,
      HackathonLocation,
      InfoWithIcon,
      ToolTipped
    },
    setup(props) {
      const { mobileView, desktopView } = useIsMobile();
      const store = useStore();

      const hover = ref(false);

      let onMouseOverTimeout = null;
      let onMouseLeaveTimeout = null;

      return {
        hackathonUrl: computed(() => {
          let url = props.hackathon.url;

          const refParams = $.param(props.refParams)
          if (refParams)
            url += "?" + refParams;

          return url;
        }),
        featured: props.hackathon.featured && (props.isFeatured == null || props.isFeatured),
        hasSidebar: !(props.isSmall || props.isMedium),
        onMouseLeave: () => {
          if (mobileView.value) return;

          if (onMouseOverTimeout) clearTimeout(onMouseOverTimeout);
          onMouseLeaveTimeout = setTimeout((() => hover.value = false), 50);
        },
        onMouseOver: () => {
          if (mobileView.value) return;

          if (onMouseLeaveTimeout) clearTimeout(onMouseLeaveTimeout);
          onMouseOverTimeout = setTimeout((() => hover.value = true), 50);
        },
        toggleManagedByDevpost: () => {
          store.dispatch("filters/setManagedByDevpostBadge", !store.getters['filters/getManagedByDevpostBadgeState']);
          store.dispatch("hackathons/search");
        },
        hover,
        mobileView,
        desktopView,
        devpostBadgeUrl
      }
    }
  }
</script>

<style scoped lang="scss">
  @import 'colors';
  @import 'challenge_state_colors';

  $tab-color: #96ACB3;

  *, a {
    color: $grey-5;
  }

  .hackathon-tile {
    background: white;
    border: 1px solid $blue-grey-2;
    border-left-width: 0;
    border-radius: 0 3px 3px 0;
    position: relative;
    transition: border-color 0.2s;

    &.hover {
      cursor: pointer;
      border-color: darken($blue-grey-2, 10%);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .tab {
        width: 30px;
        opacity: 1;
        border-radius: 0 3px 3px 0;

        i {
          opacity: 1;
        }
      }

      .status-tab {
        background-color: $tab-color;
      }

      .status-tab {
        width: 6px;
      }
    }

    .tile-anchor {
      position: relative;
      z-index: 11;
    }

    // UPCOMING
    &.upcoming {
      border-left-color: $upcoming-color;

      &.hover {
        border-color: $upcoming-color;
      }

      .tab, .status-tab {
        background: $upcoming-color;
      }
    }

    // OPEN
    &.open {
      border-left-color: $open-color;

      &.hover {
        border-color: $open-color;
      }

      .tab, .status-tab {
        background: $open-color;
      }
    }

    &.ended {
      background: $ended-background-color;
    }

    // FEATURED
    &.featured {
      border-color: $featured-color !important;

      .tab {
        background: $featured-color !important;
      }

      .status-tab {
        display: none;
      }

      &.hover {
        box-shadow: 0 1px 0 $featured-color, 0 -1px 0 $featured-color;

        .tab {
          margin: -2px 0 -2px -1px;
        }

        .featured-tab {
          margin: -2px 0 -2px;
        }
      }
    }

    // Right hand side "tab" with arrow

    .tab {
      position: absolute;
      z-index: 10;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 0;
      margin: -1px 0 -1px -1px;
      transition: width 0.1s, opacity 0.1s;
      background: $tab-color;
      opacity: 0;
      text-align: center;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -6px;
        margin-left: -4px;
        opacity: 0;
        transition: opacity 0.4s;
        color: white;
      }
    }

    .status-tab {
      width: 4px;
      border-radius: 2px 0 0 2px;
      position: absolute;
      top: -1px;
      right: 100%;
      bottom: -1px;
      background-color: $grey-3;
      transition: width 0.1s;
    }

    &.is-mobile {
      border-left-width: 1px;

      &.featured {
        border-radius: 3px;
      }

      .main-content {
        width: 100%;
      }

      &:not(.without-sidebar) {
        .main-content {
          padding-bottom: 0;
        }
      }

      .tile-anchor {
        flex-wrap: wrap;
      }

      .featured-tab {
        bottom: 100%;
        left: -1px;
        right: -1px;
        top: auto;
        width: auto;
        border-radius: 3px 3px 0 0;
        justify-content: center;
        height: 2rem;

        div {
          transform: none;
          margin: 0;
        }
      }
    }
  }

  .featured-tab {
    display: flex;
    background: $featured-color;
    position: absolute;
    left: -28px;
    top: 0;
    bottom: 0;
    margin: -1px 0 -1px;
    width: 28px;
    border-radius: 3px 0 0 3px;

    div {
      transform: rotate(-90deg);
      display: block;
      white-space: nowrap;
      margin-left: -27px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 0.8125rem;
      color: white;
      font-weight: 500;
    }

    .is-mobile & {
      position: static;
    }
  }

  .hackathon-thumbnail {
    width: 125px;
    float: left;
    border-radius: 2px;

    .is-mobile & {
      width: 60px !important;
    }
  }

  .is-small {
    .hackathon-thumbnail {
      width: 75px;
    }

    .content {
      margin-left: 95px;
    }
  }

  .main-content {
    flex-grow: 1;
    display: block;
    outline: none;
  }

  .main-content, .side-info {
    padding: 1.25rem;
  }

  .content {
    margin-left: 145px;
  }

  .side-info {
    width: 240px;
    min-width: 240px;
    font-size: 0.875rem;
    border-left: 1px solid $grey-2;

    .invite-only {
      text-decoration: underline dashed;
    }

    .label {
      font-size: 0.875rem;
    }

    .is-mobile & {
      width: auto;
    }
  }

  .fa-home {
    line-height: 25px;
  }

  h3 {
    font-size: 1.5rem;
  }

  .hackathon-status {
    min-width: 150px;
  }

  .managed-by-devpost {
    img {
      width: 16px;
      margin-right: 10px;
      margin-left: -2px;
    }

    a {
      span, i {
        color: $primary-1 !important;
      }
    }
  }
</style>
