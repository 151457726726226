import HackathonApi from "@/api/hackathon_api"

const state = () => ({
  hackathons: []
})

const mutations = {
  setHackathons: (state, hackathons) => {
    state.hackathons = hackathons;
  }
}

const getters = {
  hackathons: (state) => state.hackathons
}

const actions = {
  async fetchSimilarHackathons({ commit }) {
    const response = await HackathonApi.getSimilar();
    commit("setHackathons", response.hackathons);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
